import React, { useState } from 'react'
import Layout from "../components/layout"

const PricesPage = () => (
  <Layout>
    <h2>Priser</h2>

    <p>
      <h4>Klasse B</h4>
      <table>
        <tr>
          <td>Kjøretime</td>
          <td className={"rightcolumn"}>880,-</td>
        </tr>
        <tr>
          <td>Trinnvurdering, trinn 2</td>
          <td className={"rightcolumn"}>990,-</td>
        </tr>
        <tr>
          <td>Trinnvurdering, trinn 3</td>
          <td className={"rightcolumn"}>1320,-</td>
        </tr>
        <tr>
          <td>Sikkerhetskontroll av bilen (inkl. kursmatriell)</td>
          <td className={"rightcolumn"}>1090,-</td>
        </tr>
        <tr>
          <td>Sikkerhetskurs på bane</td>
          <td className={"rightcolumn"}>4400,-</td>
        </tr>
        <tr>
          <td>Sikkerhetskurs på vei</td>
          <td className={"rightcolumn"}>9680,-</td>
        </tr>
        <tr>
          <td>Førerprøve i Kristiansand</td>
          <td className={"rightcolumn"}>2640,-</td>
        </tr>
        <tr>
          <td>Mørkekjøring</td>
          <td className={"rightcolumn"}>1700,-</td>
        </tr>
        <tr>
          <td>B pakke - Alt obligatorisk &nbsp; <PakkeInfo/>
          </td>
          <td style={{verticalAlign: 'top'}} className={"rightcolumn"}>27500,-</td>
        </tr>
      </table>

      <h4>Klasse BE</h4>
      <table>
        <tr>
          <td>Kjøretime</td>
          <td className={"rightcolumn"}>880,-</td>
        </tr>
        <tr>
          <td>Trinnvurdering, trinn 2</td>
          <td className={"rightcolumn"}>990,-</td>
        </tr>
        <tr>
          <td>Trinnvurdering, trinn 3</td>
          <td className={"rightcolumn"}>1320,-</td>
        </tr>
        <tr>
          <td>Lastsikringskurs</td>
          <td className={"rightcolumn"}>1200,-</td>
        </tr>
        <tr>
          <td>Førerprøve i Kristiansand</td>
          <td className={"rightcolumn"}>2640,-</td>
        </tr>
        <tr>
          <td>BE pakke - Alt obligatorisk</td>
          <td className={"rightcolumn"}>7900,-</td>
        </tr>
      </table>

      <h4>Gebyrer og bøker</h4>
      <table>
        <tr>
          <td>Baneleie SkanTraf (glattkjøring)</td>
          <td className={"rightcolumn"}>1400,-</td>
        </tr>
        <tr>
          <td>Teoretisk prøve</td>
          <td className={"rightcolumn"}>380,-/340,- (*)</td>
        </tr>
        <tr>
          <td>Praktisk prøve kl. B</td>
          <td className={"rightcolumn"}>1240,-/1200,- (*)</td>
        </tr>
        <tr>
          <td>Praktisk prøve kl. BE</td>
          <td className={"rightcolumn"}>1110,-/1080,- (*)</td>
        </tr>
        <tr>
          <td>Utstedelse av førerkort</td>
          <td className={"rightcolumn"}>220,-/90,- (*)</td>
        </tr>
        <tr>
          <td>Førerkortbilde</td>
          <td className={"rightcolumn"}>70,-</td>
        </tr>
      </table>
    </p>
    <p style={{ fontSize: "small" }}>
      (*) Prisene er for betaling i skranke på trafikkstasjonen eller over nett.
    </p>
  </Layout>
)

const PakkeInfo = () => {
  const [toggle, setToggle] = useState(false)

  return (
      <>
        {!toggle && (
            <button id={'pakkeinfo_button'} className={`lenkeknapp_liten`} onClick={() => setToggle(!toggle)}>
              INFO
            </button>
        )}
        {toggle && (
            <button id={'pakkeinfo_button'} className={`lenkeknapp_liten`} onClick={() => setToggle(!toggle)}>
              LUKK
            </button>
        )}
        {toggle && (
            <div id={`pakkeinfo`} className={`pakke_boks`}>
              <h5>Pakken inkluderer</h5>
              <ul className={`pakke_liste`}>
                <li className={`pakke_liste_element`}>10 Kjøretimer </li>
                <li className={`pakke_liste_element`}>Trinnvurdering trinn 2</li>
                <li className={`pakke_liste_element`}>Trinnvurdering trinn 3</li>
                <li className={`pakke_liste_element`}>Sikkerhetskontroll av bilen</li>
                <li className={`pakke_liste_element`}>Sikkerhetskurs på bane</li>
                <li className={`pakke_liste_element`}>Sikkerhetskurs på vei</li>
                <li className={`pakke_liste_element`}>Førerprøve i Kristiansand</li>
                <li className={`pakke_liste_element`}>SMS varsling</li>
                <li className={`pakke_liste_element`}>kr 1080,- i rabatt</li>
              </ul>
              <h5>Betingelser ved kjøp av pakke</h5>
              <div style={{paddingLeft: `0.7rem`}}>
                Pakken skal forskuddsbetales, og er kun gyldig når hele pakkebeløpet er betalt.<br/>
                Pakken skal betales ved oppstart, og senest 5 dager fra bestillingen.<br/>
                Pakken er personlig og kan ikke overføres til andre.<br/>
                Pakken er gyldig i 12 mnd. fra oppstart.<br/>
                Gebyrer er ikke inkludert.<br/>
                Det er strenge krav til nivå på eleven for å kjøre obligatoriske timer, det vil derfor mest sannsynlig komme flere kjøretimer i tillegg.
                Disse betales etter gjeldende priser.
              </div>
            </div>
        )}
      </>
  )
}

export default PricesPage
